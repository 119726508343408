
body,html{
    background-color: #fed400;
    margin: 0;
    height: 100%;
}

img{
    display: block;
}

